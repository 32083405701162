import adas from '../services/adas';

const tableCsvDownload = async (tableName: string): Promise<void> => {
  try {
    const { data } = await adas.post('/DownloadCsv', { table: tableName });
    const a = document.createElement('a');
    const csvContent = data;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.setAttribute('download', `${tableName}.csv`);
    a.click();
  } catch (error) {
    throw new Error(error);
  }
};

export default tableCsvDownload;
